import React from 'react'
import { Link } from 'gatsby'
import IrisImg from '../../../Shared/IrisImg'
import AccentBorder from '../../elements/AccentBorder'
import { getSignupUrl } from '../../../../utils/url'

const signupUrl = getSignupUrl()

const Features = ({ view, images }) => (
  <div className={`features-container section features-${view}`}>
    <div className="content-container">
      <div className="resolve-container segment">
        <div className="text-container">
          <AccentBorder color="blue" />
          <p className="header">Resolve Issues From Anywhere in the World</p>
          <p className="detail">
            Driving hours to the job site and screen sharing kills productivity.
            Prospect streamlines collaboration by connecting remote teams around
            the globe in a true to scale environment. Keep the team present and
            productive with Prospect.
          </p>
          <Link href={signupUrl}>Try Prospect For Quest</Link>
        </div>
        <div className="image-container">
          <IrisImg className="resolve" file={images.resolve} alt="resolve" />
        </div>
      </div>

      <div className="segment-divider" />

      <div className="deliver-container segment segment-reverse">
        <div className="text-container">
          <AccentBorder color="blue" />
          <p className="header">
            Deliver Error-Free Building Information Models
          </p>
          <p className="detail">
            Model errors and clashes become obvious in 1:1 scale. That’s why VDC
            and BIM teams use Prospect to catch errors and quickly spot clashes
            that would have remained hidden using only 2D and 3D QA/QC methods.
          </p>
          <Link href={signupUrl}>Try Immersive QA/QC Today</Link>
        </div>
        <div className="image-container">
          <IrisImg className="error" file={images.error} alt="error" />
        </div>
      </div>

      <div className="segment-divider" />

      <div className="leverage-container segment">
        <div className="text-container">
          <AccentBorder color="blue" />
          <p className="header">Leverage VDC on the Job Site</p>
          <p className="detail">
            VDC efforts don’t make it to the job site, resulting in rework costs
            and project delays. Prospect maximizes BIM by putting subcontractors
            into the model early and often so installation is done right the
            first time. Improving trade comprehension has never been easier.
          </p>
          <Link href={signupUrl}>See Prospect For Quest in Action</Link>
        </div>
        <div className="image-container">
          <IrisImg className="vdc" file={images.vdc} alt="vdc" />
        </div>
      </div>

      <div className="segment-divider" />

      <div className="workflow-container segment segment-reverse">
        <div className="text-container">
          <p className="special-note">
            Design intent is easiest to comprehend in 1:1 scale. That’s why
            Prospect makes it super simple to host coordination meetings, QA/QC
            and subcontractor training in virtual reality.
          </p>
          <div className="special-list">
            <div className="li">
              <div className="bullet" />
              <p>Coordination Meetings</p>
            </div>
            <div className="li">
              <div className="bullet" />
              <p>Model QA/QC</p>
            </div>
            <div className="li">
              <div className="bullet" />
              <p>Subcontractor Training</p>
            </div>
          </div>
        </div>
        <div className="image-container">
          <IrisImg className="const" file={images.const} alt="const" />
        </div>
      </div>
    </div>
  </div>
)

export default Features
