import React from 'react'
import IrisImg from '../../../Shared/IrisImg'
import './Hero__WithForm.global.scss'

class Hero extends React.Component {
  state = {
    portalId: '6115928',
    formId: 'a395d156-4a36-493d-b4db-61e6f5c17f30',
  }

  setPlaceholder = () => {
    const input = document.getElementById(`email-${this.state.formId}`)
    input.placeholder = 'Work Email Address'

    // const button = document.getElementsByClassName('hs-button')[0]
    // button.value = 'Request Access'
  }

  render() {
    const { view, images } = this.props
    const linkHref = "https://www.meta.com/experiences/4377595852279163/"
    return (
      <div className={`quest-hero-container section hero-${view}`}>
        <div className="content-container">
          <div className="image-container">
            <IrisImg className="splash-image" file={images.hero} />
          </div>
          <div className="info-container">
            <div className="text-container">
              <p className="header">Prospect for</p>
              <p className="header header-second">Meta Quest</p>
              <p className="subtitle">
                Immersive Collaboration & Coordination Unleashed
              </p>
            </div>

            <div className="cta-container">
            <a href={linkHref} className="subtitle-link" target="_blank" rel="noopener noreferrer">
              Access Prospect for Meta Quest
            </a>
            </div>

            <div className="file-formats">
              <p className="subtitle">Support for 3D file formats:</p>
              <div className="integrations-container">
                {Object.keys(images.integrations).map(k => (
                  <div key={k} className="integration-icon-container">
                    <IrisImg
                      className={`integration-icon integration-${k}-icon`}
                      file={images.integrations[k]}
                      alt={k}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Hero
