import React, { memo } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../components/Shared'
import Quest, { helmetProps } from '../components/Quest'

export const query = graphql`
  {
    splash: file(
      name: { eq: "1_quest_headset" }
      relativeDirectory: { eq: "quest" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 507, maxWidth: 590, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    sketchupIcon: file(
      relativeDirectory: { eq: "prospect/integrations" }
      name: { eq: "sketchup" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 34) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    revitIcon: file(
      relativeDirectory: { eq: "prospect/integrations" }
      name: { eq: "revit" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 34) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    rhinoIcon: file(
      relativeDirectory: { eq: "prospect/integrations" }
      name: { eq: "rhino" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 34) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    fbxIcon: file(
      relativeDirectory: { eq: "prospect/integrations" }
      name: { eq: "fbx" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 34) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    navisworksIcon: file(
      relativeDirectory: { eq: "prospect/integrations" }
      name: { eq: "navisworks" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 34) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
    objIcon: file(
      relativeDirectory: { eq: "prospect/integrations" }
      name: { eq: "obj" }
    ) {
      id
      name
      childImageSharp {
        fixed(height: 34) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }

    resolve: file(
      name: { eq: "2.1_features_resolve" }
      relativeDirectory: { eq: "quest" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 389, maxWidth: 445, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    error: file(
      name: { eq: "2.2_features_error" }
      relativeDirectory: { eq: "quest" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 444, maxWidth: 430, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    vdc: file(
      name: { eq: "2.3_features_vdc" }
      relativeDirectory: { eq: "quest" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 482, maxWidth: 498, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    const: file(
      name: { eq: "2.4_features_const" }
      relativeDirectory: { eq: "quest" }
    ) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 587, maxWidth: 587, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default memo(({ data }) => (
  <>
    <Helmet {...helmetProps} />
    <Quest
      images={{
        splash: {
          hero: data.splash,
          integrations: {
            sketchup: data.sketchupIcon,
            revit: data.revitIcon,
            rhino: data.rhinoIcon,
            fbx: data.fbxIcon,
            navisworks: data.navisworksIcon,
            obj: data.objIcon,
          },
        },
        features: {
          resolve: data.resolve,
          error: data.error,
          vdc: data.vdc,
          const: data.const,
        },
      }}
    />
  </>
))
