import React from 'react'
import PropTypes from 'prop-types'
import './AccentBorder.global.scss'

const AccentBorder = ({ color }) => <div className={`accent-border ${color}`} />

AccentBorder.propTypes = {
  color: PropTypes.string.isRequired,
}

export default AccentBorder
