import React from 'react'


class SignupFooter extends React.Component {
  state = {
    portalId: '6115928',
    formId: 'a395d156-4a36-493d-b4db-61e6f5c17f30',
  }

  componentDidMount() {
    setTimeout(() => {
      this.setPlaceholder()
    }, 2000)
  }

  setPlaceholder = () => {
    const input = document.getElementsByClassName('hs-input')[1]
    if (input) input.placeholder = 'Work Email Address'

    // const button = document.getElementsByClassName('hs-button')[1]
    // button.value = 'Request Access'
  }

  render() {
    const { view } = this.props
    const linkHref = "https://www.meta.com/experiences/4377595852279163/"
    return (
      <div className={`signup-footer-container section signup-footer-${view}`}>
        <div className="content-container">
          <div className="form-container">
            <a href={linkHref} className="subtitle-link" target="_blank" rel="noopener noreferrer">
              Access Prospect for Meta Quest
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default SignupFooter
