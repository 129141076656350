import React from 'react'
import AccentBorder from '../../elements/AccentBorder'
import WirelessIcon from '../../../../images/quest/collab_wireless.svg'
import LibraryIcon from '../../../../images/quest/collab_library.svg'
import MultiuserIcon from '../../../../images/quest/collab_multiuser.svg'
import { getSignupUrl } from '../../../../utils/url'
import { Link } from 'gatsby'
import { IrisImg } from '../../../Shared'

const Collaboration = ({ view }) => (
  <div className={`collaboration-container section collaboration-${view}`}>
    <div className="content-container">
      <div className="header-container">
        <AccentBorder color="purple" />
        <p className="header">Collaboration Made Easy</p>
      </div>
      <div className="features-container">
        <div className="feature-container">
          <div className="svg-container">
            <WirelessIcon style={{ width: '100px' }} />
          </div>
          <div className="subtitle">Go Wireless</div>
          <p className="detail">
            The Meta Quest doesn't need to be plugged into a computer.
          </p>
        </div>
        <div className="feature-container">
          <div className="svg-container">
            <MultiuserIcon style={{ width: '100px' }} />
          </div>
          <div className="subtitle">Meet in VR</div>
          <p className="detail">
            Up to 12 people can meet together in VR from anywhere.
          </p>
        </div>
        <div className="feature-container">
          <div className="svg-container">
            <LibraryIcon style={{ width: '100px' }} />
          </div>
          <div className="subtitle">Synced Project Library</div>
          <p className="detail">
            Access your files anywhere and easily share them with collaborators.
          </p>
        </div>
      </div>
      {
        <div className="cta-container">
          <a href={getSignupUrl()}>
            <button className="iris-cta-button">Try Prospect Now</button>
          </a>
        </div>
      }
    </div>
  </div>
)

export default Collaboration
