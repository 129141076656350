import React from 'react'

const data = [
  {
    q: 'Do I need a PC to use The Quest? How powerful?',
    a:
      "The Quest is a fully self-contained device, so you do not need a PC to enter IrisVR experiences. If you plan to process your own 3D files, you'll need a Windows PC with Prospect installed to generate an IrisVR experience, and then sync the file so it can be accessed by The Quest.",
  },
  {
    q:
      'Should I buy a Quest instead of a Rift or a Vive (or other desktop VR headset)?',
    a:
      "For most firms, it will still make sense to have a desktop VR headset in the conference room and demo spaces in the office. However, standalone devices such as The Quest are perfect for most other scenarios since they are easy to travel with and require minimal set up to start using. If you have project teams that don't have desktop VR, it's worth evaluating The Quest first to see if that form factor fits their needs.",
  },
  {
    q: 'Can The Quest load large files?',
    a:
      'Since The Quest has a mobile processor it will support smaller files than what you can run on a fully equipped desktop PC.  The factors that contribute to performance include the size of textures, the complexity of the geometry, and the number of individual elements in the scene. With each release, we’ll continue to improve our optimization engine. Please contact support if you’d like advice on how to optimize your files for Quest.',
  },
  {
    q: 'Where are my files stored? Is it secure?',
    a: `Security is incredibly important to our team, and we follow industry best practices to ensure your data is always secure. Your files are stored locally on your own computer, in a proprietary file format, and only when you select "Sync" are they hosted on our web servers. Networking and data transfer processes are fully encrypted, ensuring that your files will only be visible to your trusted collaborators and partners.`,
  },

  {
    q:
      'How do I set my team up with multiple Quests? How much would that cost?',
    a: (
      <p>
        If you plan to purchase multiple headsets for your team, please reach
        out to <a href="mailto:sales@irisvr.com">sales@irisvr.com</a> since we
        offer significant bulk discounts and hardware bundles.
      </p>
    ),
  },
]

const FAQ = ({ view }) => (
  <div className={`faq-container section faq-${view}`}>
    <div className="content-container">
      <div className="header-container">
        <p className="header">Frequently Asked Quest-ions</p>
      </div>
      {data.map(d => (
        <div className="qa-container" key={d.q}>
          <div className="question">{d.q}</div>
          <div className="answer">{d.a}</div>
        </div>
      ))}
    </div>
  </div>
)

export default FAQ
