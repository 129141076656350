import { getWindowLocationOrigin } from '../../utils/window'
import { generateMetaTags } from '../../utils/helmet'

const title = 'Meta Quest 2 and Prospect | IrisVR'
const description =
  'Meta Quest 2 enables BIM & VDC teams to do model coordination, clash detection, and host VR meetings in Prospect. Supports Revit, Navisworks, and 3D models.'
const url = `${getWindowLocationOrigin()}/oculus-quest`
const image =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Quest.jpg.png'
const imageWidth = 1200
const imageHeight = 630

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
